<template>
  <v-container id="user-profile" fluid tag="section">
    <base-material-card
      icon="mdi-account-outline"
      title="Profile Details"
      class="px-5 py-3"
      color="primary"
    >
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >User Name</v-text
                  >
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="username"
                    style="max-width: 300px"
                    dense
                    :rules="[rules.required]"
                    solo
                    placeholder="Input user name ..."
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >Enter New Email
                  </v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    v-model="email"
                    style="max-width: 300px"
                    dense
                    :rules="[rules.required, rules.email]"
                    solo
                    placeholder="Enter New Email  ..."
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >First name</v-text
                  >
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    v-model="firstname"
                    style="max-width: 300px"
                    dense
                    :rules="[rules.required]"
                    solo
                    placeholder="Input first name..."
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >Surname
                  </v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    v-model="lastname"
                    style="max-width: 300px"
                    dense
                    :rules="[rules.required]"
                    solo
                    placeholder="Input surname..."
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >New Password
                  </v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    style="max-width: 300px"
                    dense
                    solo
                    type="password"
                    v-model="newPassword"
                    placeholder="Input new password..."
                    :rules="[rules.containString, rules.special, rules.counter]"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" sm="4" class="mt-3">
                  <v-text style="color: black; font-size: 16px"
                    >Confirm Password
                  </v-text>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex">
                  <v-text-field
                    v-model="confirmPassword"
                    style="max-width: 300px"
                    dense
                    solo
                    :rules="[
                      rules.confirmpassword,
                      rules.containString,
                      rules.special,
                      rules.counter,
                    ]"
                    required
                    type="password"
                    placeholder="Input confirm password..."
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="d-flex text-center justify-center">
            <v-btn class="primary" :loading="isLoading" @click="update"
              >Update</v-btn
            >
          </div>
        </v-container>
      </v-form>
    </base-material-card>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { APIdoUpdateProfile } from "@/api/common/api";
export default {
  //
  data() {
    return {
      userDetail: {},
      username: "",
      email: "",
      firstname: "",
      lastname: "",
      surname: "",
      newPassword: "",
      confirmPassword: "",
      isLoading: false,
      notification: false,
      notificationTxt: "",
      notificationColor: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length >= 9 || "Min 9 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        confirmpassword: (value) => {
          return this.passwordCheck() || "Confirm password is not match";
        },
        special: (value) => {
          return /[!@#\$%\^&\*]/.test(value) || "At least 1 special symbol";
        },
        containString: (value) => {
          //For latin letters only
          return /[A-Za-z]/.test(value) || "At least one letter";
        },
        number: (value) => {
          return /\d/.test(value) || "At least 1 number";
        },
      },
    };
  },
  mounted() {
    // this.userDetail = this.getUserDetails;
    this.username = this.getUserDetails.username;
    this.email = this.getUserDetails.email;
    this.firstname = this.getUserDetails.firstname;
    this.lastname = this.getUserDetails.lastname;
    this.newPassword = "";
    this.confirmPassword = "";
  },
  computed: {
    ...mapGetters({
      getUserDetails: "getUserData",
    }),
  },

  methods: {
    ...mapActions({
      doGetSettings: "doGetSettings",
      doGetUsers: "doGetUsers",
    }),
    passwordCheck() {
      if (
        (this.newPassword === "" && this.confirmPassword === "") ||
        this.newPassword === this.confirmPassword
      ) {
        return true;
      } else {
        return false;
      }
    },
    update() {
      const postData = {
        username: this.username,
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.newPassword,
        confirm: this.confirmPassword,
      };
      this.isLoading = true;
      APIdoUpdateProfile(postData)
        .then((res) => {
          this.notification = true;
          this.notificationTxt = "Successfully Updated";
          this.notificationColor = "success";
          this.doGetSettings();
          this.isLoading = false;
          this.doGetUsers();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.status === "unauthorized") {
            localStorage.removeItem("user");
            this.$router.push("/");
          }
        });
    },
  },
};
</script>
